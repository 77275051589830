/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'OneNightSans-Normal';
  src: url('./assets/fonts/OneNightSans/OneNightSans-Extended.otf');
}

@font-face {
  font-family: 'OneNightSans-Bold';
  src: url('./assets/fonts/OneNightSans/OneNightSans-ExtendedBlack.otf');
}

body {
  font-family: 'Raleway', sans-serif;
}

footer {
  background-color: #0b0339;
}

footer ul {
  display: flex;
  justify-content: center;
}

footer ul li::before {
  content: "|     ";
}

footer ul li:first-child::before {
  content: "";
}

.navbar {
  background-color: #0b0339;
  padding: 15px 0;
}

.items-menu {
  font-size: 20px;
  justify-content: space-between;
  width: 100%;
}

.logo {
  height: 50px;
}

.eglow-button-green {
  border: 1px solid #00bdc5;
  border-radius: 20px 20px 20px 20px;
  padding: 0.5rem 1rem;
}

#inicio {
  background-image: url('/assets/img/banner.jpg');
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#inicio h1 {
  font-size: 25px;
}

#inicio p {
  font-size: 23px;
  padding: 20px 0;
}

#inicio p span {
  color: #00bdc5;
}

#inicio a {
  border: 2px solid #00bdc5;
  font-size: 18px;
  padding: 10px;
  width: 200px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  margin: 0 16px;
  margin-bottom: 0px;
  display: inline-block;
  border-radius: 20px;
  transition: 0.3s all ease;
  margin-bottom: 10px;
}


#nosotros {
  background-image: url('/assets/img/fondo-nosotros.svg');
  background-size: cover;
  padding: 2rem 0;
}

#nosotros h1 {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  color: #00bdc5;
  margin-bottom: 10px;
}

#nosotros .container-team {
  margin-top: 40px;
}

#nosotros .item-team {
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  height: 200px;
  width: 200px;
}

#nosotros .item-team .description {
  display: flex;
  background-image: linear-gradient(-25deg, #00bdc596 0%, #3d3670 73%);
  flex-direction: column;
  justify-content: center;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  visibility: hidden;
  opacity: 0;
}

#nosotros .item-team .description .position {
  margin-top: 1rem;
  color: #00f5ff;
  font-family: 'Pacifico', cursive;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

#nosotros .item-team img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#nosotros .item-team:hover .description {
  visibility: visible;
  opacity: 1;
}

#casos-exito {
  background: linear-gradient(45deg, #2e7590 0%, #00bdc5 100%);
  padding: 2rem 0;
}

#casos-exito h1 {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  margin-bottom: 10px;
}

#casos-exito .list-items {
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 4px;
  padding-bottom: 20px;
  height: 365px;
  width: 100%;
}

.casos-exito-item {
  height: 345px;
  width: 345px;
  position: relative;
}

.casos-exito-item img {
  height: 345px;
  width: 345px;
}

.casos-exito-item .description {
  visibility: hidden;
  opacity: 0;
  background-color: #00000070;
  position: absolute;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;

  height: 300px;
  width: 300px;

}

.casos-exito-item:hover .description {
  visibility: visible;
  opacity: 1;
}

#clientes {
  background-image: url('/assets/img/fondo-nosotros.svg');
  background-size: cover;
  padding: 2rem 0;
}

#clientes h1 {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  color: #00bdc5;
  margin-bottom: 10px;
}

#clientes .item {
  border: 1px solid #00bdc5;
  width: auto;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#clientes .item-selected, #clientes .item:hover {
  background-color: #00bdc5;
  color: white;
}

#clientes .item:hover svg, #clientes .item-selected svg {
  filter: invert(50%) sepia(0%) saturate(7476%) hue-rotate(66deg) brightness(206%) contrast(111%);
}

#clientes .content img {
  width: 372px;
}

#mapa {
  background-image: linear-gradient(#41308d 0%, #170f45 100%);
  padding: 2rem 0;
}

#mapa h1 {
  font-family: 'Pacifico', cursive;
  font-size: 50px;
  color: white;
  margin-bottom: 10px;
}

#mapa .social-networks a {
  width: 50px;
  height: 50px;
}

.awards img {
  width: 155px;
}

#modal {
  background-color: rgba(0, 0, 0, 0.5);
}


.button {
  background: #00BDC5;
  color: white;
}


.input-error-message {
  color: red;
  font-weight: 400 !important;
  font-size: 14px;
}
